// ---------------------------------------------- Favorities popups ---------------------------------------------
.fav-body{
  padding-top: 0px !important;
  p{
  font-size: 13px;
  text-align: justify;
  i{
      color: red;
  }
  }
}
.fav-ser{
  padding: 0px !important;
}
.code{
height: 5rem;
.reg_12{
  top: -1.5rem;
  .input-group-prepend{
  width: 15% !important;
}
}
.comp-name12{
  width: 85.16% !important;
}
.comp-name1{
width: 85%;
}
}
//------------------------------------------Awards Popup---------------------------------------------------------
.award-text{
.MuiTextField-root{
  @media only screen and (max-width: 600px){
      width: inherit !important;
      }
}
.MuiOutlinedInput-input {
  height: 6rem;
  @media only screen and (max-width: 600px){
      height: 1.1876rem !important;
      }
}
}
.award-add{
  color: #5cbcb1;
  @media only screen and (max-width: 600px){
      padding: 0px !important;
      }
}
.awd-date{
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 15px;
}
.brandCard{
  @media only screen and (max-width: 600px){
      margin-left: 0px !important;
      }
}
.res-padr{
  @media only screen and (max-width: 600px){
      padding-right: 0px !important;
      }
}
// -------------------------------------------------- title design -----------------------------------------------
.pg-title{
  text-align: center;
  padding-top: 1.6876rem;
}
.pg-tit-img{
  text-align: center;
}
.tit-card{
  margin-bottom: 0px !important;
  .icon-tag{
      border-right: 2px solid #e9ecef;
  }
}
.card-wrapper{
  padding: 0px !important;
}
// ------------------------------------------------- Listing styles ---------------------------------------------------------
.list-tab{
  li{
    display: inline-block;
  }
  .list-main{
      width: 100%;
      margin-right: 10px;
      @media only screen and (max-width: 600px){
          width: 100% !important;
          }
  
      .nav-link{
          width: 100%;
          height: 5rem;
          padding: 20px;
          background-color: #e9ecef;
          @media only screen and (max-width: 600px){
              width: 100% !important;
              }
          }
      }
      .list-ven{
        width: 17%;
        height: 8rem;
        margin-right: 10px;
        @media only screen and (max-width: 600px){
            width: 100% !important;
            }
            .col-6 {
              flex: 0 0 100%;
              max-width: 100%;
              // text-align: center;
          }
          .nav-link{
            width: 100%;
            height: 8rem;
            padding: 20px;
            background-color: #e9ecef;
            cursor: pointer;
            @media only screen and (max-width: 600px){
                width: 100% !important;
                }
            }
          }
 
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
      background-color: #fff !important;
      border-bottom: none !important;
  }
  .icon-tag1{
      
      flex: 0 0 15.66667% !important;
      max-width: 21.66667% !important;
  }
  .icon-tag2{
      border-left: 2px solid #b8b8b8;
      @media only screen and (max-width: 600px){
          padding-left: 0px !important;
          }
  }
  .sub-a{
      a{
          color: #000;
      }
  }
  .tab-content{
      background-color: #fff !important;
  }
}
.list-adtab{
  margin-right: 0.3rem;
  cursor: pointer;
  height: 6rem;
  padding-top: 3rem;
  width: 10%;
  @media only screen and (max-width: 600px){
      width: 50% !important;
      padding-top: 0px !important;
  }
  .nav-link{
      background-color: #e9ecef;
      padding-top: 0.6rem;
      height: 5rem;
      text-align: center;
      @media only screen and (max-width: 600px){
          width: 100% !important;
          text-align: center !important;
      }
      h4{
        @media only screen and (max-width: 1600px) and (min-width: 900px){
          line-height: 1 !important;
          margin-bottom: 0.3rem !important;
        }
      }
  }
}
.res-padr1{
  @media only screen and (max-width: 1600px){
      padding-right: 0px !important;
  }    
}


/////--------------------------------------channel detail----------------------------------------
.Auth-img{  
    text-align: center;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    overflow: hidden;
  }
  .ft-nor{
    font-weight: 500 !important;
  }
.complog{
position: absolute;
top: -4.5rem;
}
.curs-point{
cursor: pointer;
}
.butn-f{
border-radius: 50px;
height: 2.5rem;
background-color: #0a66c2;
border-color: #0a66c2;
color: #ffff;
}
.butn-f:hover{
border-radius: 50px;
height: 2.5rem;
background-color: #004182;
border-color: #004182;
color: #ffff;
}
.tet{
position: absolute;
right: 1rem;
font-size: 23px;
top: 1rem;
z-index: 1;
width: 40px;
height: 40px;
padding: 0.2rem;
text-align: center;
border-radius: 50%;
background-color: #d1d2cedb;
}
.cnect-brnd{
position: absolute;
right: 7rem;
top: 9rem;
width: 19%;
text-align: right;
}

.faplus1{
color: #5cbcb1;
font-size: xx-large;
cursor: pointer !important;
}

.product-card {
padding:25px;
  background: #fff;
  width: 100%;
  height: 28rem;
  position: relative;
  border-radius: 0.375rem;
  overflow: visible;
  margin: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: inline-block; 
  border: 1px solid rgb(184, 184, 184);
  cursor: pointer;

.set-image {
  img{

    width:50%;
    height:100%;
    background-color: #fff;
    object-fit: contain;
    }
    border-radius: 0.375rem;
    // border: 1px solid rgb(184, 184, 184);
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  font: bold 20px Sans-Serif;
  text-transform: uppercase;
  // display: table;
  .product-title{
    width: 46%;
    text-align: center;
    overflow: hidden;
    height: 74%;
    padding: 95px 10px 5px 5px;
    display: inline-block;
    position: absolute;
    
    
  }
 
  
}

.set-image p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  line-height: 25px;
  color: #d2d2d2; }


}

.ribbon-wrapper-8 {
width: 115px;
height: 115px;
overflow: hidden;
position: absolute;
top: 13px;
right: -11px; }

.ribbon-8 {
font: bold 15px Sans-Serif;
line-height: 18px;
color: #333;
text-align: center;
text-transform: uppercase;
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-ms-transform: rotate(45deg);
-o-transform: rotate(45deg);
position: relative;
padding: 7px 0;
left: -4px;
top: 30px;
width: 158px;
background-color: #6daaab;
color: #fff;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
letter-spacing: 0.5px;
box-shadow: -3px 5px 6px -5px rgba(0, 0, 0, 0.5);
outline: 1px dashed #fff;
outline-offset: -4px; }

.ribbon-8:before, .ribbon-8:after {
content: "";
border-top: 4px solid #4e7c7d;
border-left: 4px solid transparent;
border-right: 4px solid transparent;
position: absolute;
bottom: -4px; }

.ribbon-8:before {
content: "";
position: absolute;
left: 0px;
top: 100%;
z-index: -1;
border-left: 4px solid #4e7c7d;
border-right: 4px solid transparent;
border-bottom: 4px solid transparent;
border-top: 4px solid #4e7c7d; }

.ribbon-8:after {
content: "";
position: absolute;
right: 7px;
top: 100%;
z-index: -1;
border-left: 4px solid transparent;
border-right: 4px solid #4e7c7d;
border-bottom: 4px solid transparent;
border-top: 4px solid #4e7c7d; }

.key-card {
padding:25px;
  background: #fff;
  // width: 100%;
  // height: auto;
  width: 280px;
  height: 325px;
  position: relative;
  border-radius: 0.375rem;
  overflow: visible;
  margin: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: inline-block; 
  border: 1px solid rgb(184, 184, 184);
  
  .set-headline {
    width: 100%;
    height: 10%;
    // background: #eee;
    // margin: 20px; }
    h3{
      white-space: nowrap;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
.set-image {
  img{

    width:100%;
    height:100%;
    background-color: #fff;
    object-fit: contain;
    }
    border-radius: 0.375rem;
    // border: 1px solid rgb(184, 184, 184);
    width: 100%;
    height: 80%;
  background: #eee;
  overflow: hidden;
  font: bold 20px Sans-Serif;
  text-transform: uppercase;
  // display: table;
  .product-title{
    width: 46%;
    text-align: center;
    overflow: hidden;
    height: 80%;
    padding: 28px;
    display: inline-block;
    position: absolute;
  }
}

.set-image p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  line-height: 25px;
  color: #d2d2d2; }


}
.ribbon-wrapper-3 {
  width: 106px;
  height: 108px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  left: -6px; }

.ribbon-3 {
  font: bold 15px Sans-Serif;
  line-height: 18px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -33px;
  top: 26px;
  width: 150px;
  background-color: #2c9db7;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px; }

.ribbon-3:before, .ribbon-3:after {
  content: "";
  border-top: 4px solid #4e7c7d;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  bottom: -4px; }

.ribbon-3:before {
  left: 0; }

.ribbon-3:after {
  right: 0; }

  .plan-badge{
  
    position: absolute;
      width: 15%;
      top: 5rem;
      right: 4rem;
      height: 3rem;
      @media only screen and (max-width: 600px){
        width: 50% !important;
        right: 1rem !important;
    }
    @media only screen and (max-width: 900px) and (min-width: 600px){
      width: 39% !important;
    }
    @media only screen and (device-width: 1440px){
      width: 22% !important;
    }
    @media only screen and (device-width: 1400px){
      width: 23% !important;
    }
    @media only screen and (device-width: 1366px){
      width: 22% !important;
    }
    @media only screen and (device-width: 1360px){
      width: 22% !important;
    }
    @media only screen and (device-width: 1280px){
      width: 24% !important;
    }
    @media only screen and (max-width: 1680px) and (min-width: 1441px){
      width: 19% !important;
    }
    .c-1{
      height: 3rem;
    }
    .plan-txt{
      height: 100%;
      border-top-left-radius: 15px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 15px;
      background-color: rgba(0, 0, 0, 0.7);
      text-align: center;
      padding-top: 0.6rem;
      padding-right: 1.9rem;
      padding-bottom: 0.6rem;
      padding-left: 0.6rem;
      @media only screen and (max-width: 600px){
        text-align: left !important;
        padding-top: 0.3rem;
    }
      h3{
      color: #fff !important;
      @media only screen and (max-width: 600px){
        font-size: 0.8rem !important;
    }
  
    @media only screen and (max-width: 2560px) and (min-width: 601px){
      font-size: 1rem !important;
    }
    @media only screen and (device-width: 1920px){
      font-size: 0.9rem !important;
    }
  
    }
  }
    .plan-txt1{
      height: 100%;
      border-radius: 50%;
      width: 3rem;
      margin-left: -26px;
      background-color: #fff;
      padding: 0.7rem 0.9rem;
      i{
        font-size: 1.5rem;
        margin-left: 0.1rem;
        color: red;
      }
      .str-i{
        position: absolute !important;
      font-size: 0.7rem !important;
      left: -9px !important;
      top: 0.9rem !important;
      color: #fff !important;
      }
    }
  }

#channel-detail{
  .rightdivider{
      border-right: 2px solid #e9ecef;
  }
.double-badge{
  cursor: pointer;
span:nth-child(1){
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background-color: #FF6347;
  color: white;
  font-size: 0.9000rem;
}
span:nth-child(2){
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0px !important;
  background-color: white;
  color: black;
  font-size: 0.9000rem;
  
}
}
.counts{
  .double-badge{
      // display: inline-flex;
      span{
      width: 80%;
      border-radius: 0.375rem;
      }
  span:nth-child(1){
      background-color: rgb(44, 157, 183);
      display: inline-block;
      color: white;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
  }
  span:nth-child(2){
     background-color: #eaecfb;
     color: black;
     display:inline-block;
     border-top-right-radius: 0;
     border-top-left-radius: 0;
    
  }
}
}
.MuiRating-root {
  color: black !important;
}
#Countries{
  .selling,.target{
      border: 2px dashed #aaa;
     
      border-radius: 10px;
  }
}

}
.border_bottom{
  border-bottom: 1px solid #b8b8b8;
}
#leftFilter{
background-color: #f8f9fe;
  li{
      padding: 0.5rem 0.5rem !important;
     // cursor: pointer;
      border: none;
  }
}
.right-search{
position: absolute;
top: 4.2rem;
right: 12rem;
width: 20rem;
}
.right-sort{
position: absolute;
top: 4.9rem;
right: 5rem;
}
.input-group-text{
  background-color: #F7FAFE;
  border: none;
}
.avatar img{
  object-fit: contain;
}

#channel-detail{
.badges{
  span:nth-child(1){
      background-color: #FF6347;
      color: white;
      font-size: 0.9000rem;
  }
}
.outer_badge{
  background-color: white;
  color: rgb(255, 99, 71);
  border: 1px solid rgb(255, 99, 71);
}
.outer_badge1{
background-color: white;
color: #2c9db7;
border: 1px solid #2c9db7;
font-size: 0.9rem;
}
.btn-icon-only {
  width: 1.375rem;
  height: 1.375rem;
}

}
.conne-card{
flex: 0 0 20% !important;
max-width:20% !important;
@media only screen and (max-width: 600px){
  flex: 0 0 100% !important;
  max-width: 100% !important;
} 
}
.conne{
@media only screen and (max-width: 1600px) and (min-width: 900px){
  flex: 0 0 33% !important;
  max-width: 33% !important;
}
}
.B-prod{
max-width:  12rem;
height: 3rem;
padding-top: 17px;
font-weight: 700;
font-size: 0.9000rem !important;
background-color: transparent;
color: #32325d;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
border: 1px solid #b8b8b8;
}
.detailbadge{
max-width:  12rem;
border: 1px solid #b8b8b8;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
font-size: 13px;
background-color: transparent;
}
.B-prod1{
  width: 100%;
  height: 3rem;
  // padding-top: 17px;
  font-weight: 700;
  font-size: 0.9000rem !important;
  background-color: #2c9db7;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 3px;
  border-radius: 3px;
  }
.detv-contact{
  border: 1px solid #b8b8b8;
  border-radius: 10px;
  padding: 25px 15px;
  box-shadow: 0 4px 9px 0 rgba(0,0,0,.18),0 5px 5px 0 rgba(0,0,0,.15) !important;
  height: 20rem;
  overflow: hidden;
.conne-img{
    width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      @media only screen and (max-width: 1600px) and (min-width: 900px){
        width: 70px !important;
        height: 70px !important;
  }
}
  .conne-comp{
      width: 100px;
      height: 100px;
      border-radius: 10%;
      overflow: hidden;
      margin: 0px 10px 0px 24px;
      @media only screen and (max-width: 1600px) and (min-width: 900px){
        width: 70px !important;
        height: 70px !important;
  }
  }
button{
border-radius: 15px !important;
}
}
.conn-btn{

.btn-icon-only{
  width: 2rem !important;
  height: 2rem !important;
  @media only screen and (max-width: 1600px) and (min-width: 900px){
    width: 1.5rem !important;
    height: 1.5rem !important;
}
}
.btn-slack1{
  color: #fff;
  background-color: #5cbcb1;
  border-color: #5cbcb1;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
}
.conn-cont{
height: 8rem;
}
.ribbon-9{
top: -15rem !important;
right: -40px !important;
width: 170px !important;
}
.minheit{
min-height: 11rem;
}
.keyProductSmall{
  width:190px !important;
  height: 200px !important;
  }
  .keyheight{
    height: 70% !important;
  }
// ------------------------------------------------- Listing Page ---------------------------------------------------------
#listing{
  .list-tab .tab-content {
      background-color: rgb(248, 249, 254) !important;
  }
}
.heart-o,.heart{
position: absolute;
top: -125px;
right: 10px;
font-size: 2rem;
@media only screen and (min-width: 1900px){
  position: absolute !important;
  top: -100px !important;
  right: 20px !important;
  font-size: 2rem !important;
}
@media only screen and (max-width: 600px){
position: initial !important;
}
@media screen and (max-width: 1124px) and (min-width: 602px) {
position: relative !important;
top: 32px !important;
right: -70px !important;
font-size: 2rem !important;
}
}
.heart{
color: #dcdcdc;
}
.heart:active{
color: #e64c3c;
}
.fav-ser1{
background-color: white;
padding-bottom: 1.5rem !important;
.MuiOutlinedInput-root {
  border-radius: 0px !important;
}
.MuiAutocomplete-paper {
  box-shadow: none !important;
  height: auto !important;
}
.MuiAccordionSummary-root{
  padding: 10px 16px !important;
}
.MuiOutlinedInput-notchedOutline {
  border-left: none !important; 
  border-top: none !important;
  border-right: none !important;
}
.list-group{
  position: relative !important;
  top:-2rem !important;
}
.list-group-item {
  padding: 0px !important;
  border: none !important;
  white-space: nowrap !important;
}
.MuiCheckbox-root {
  margin-right: 0px !important;
  padding: 3px !important;
}
.MuiTypography-body1 {
  width: 100% !important;
}
::-webkit-scrollbar {
display: none;
}
ul:hover{
::-webkit-scrollbar{
  display: block;
  width: 5px;
  height: 5px;
}
}
/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888;
border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555;
}
.MuiAutocomplete-popper {
z-index: 1;
width: 100% !important;
}
}

// ---------------------------------------------------- Drawer ------------------------------------------------------------
.TopDrawer{
  .ant-drawer-content-wrapper{
    width: 100% !important;
  }
  .ant-drawer-body {
    padding: 24px 4rem !important;
  }
}
.site-form-in-drawer-wrapper {
  padding: 0px 16px;
  text-align: center;
}
.ant-drawer-content-wrapper{
    width: 50% !important;
    @media screen and (max-width: 1124px) and (min-width: 602px) {
      width: 720px !important;
  }
    @media only screen and (max-width: 600px){
      width: 100% !important;
  }
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  -webkit-transition: height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer > * {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left {
  left: 0;
}
.resp-logo .imgContiner{
  width: 115px !important;
}
.BrandCloseSvg{
  position: relative;z-index: 1;
}
.dash-card{
  width: 18%;margin-right: 10px;
}
.dash-card .card{
  margin-bottom: 10px;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
          box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
          box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
          transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
          box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
          box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
          animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - var(--scroll-bar));
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-drawer-wrapper-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 10px 10px;
  border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  -webkit-transition: opacity 0.3s linear, height 0s ease 0.3s;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-drawer-open-content {
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
          box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
}
//   ---------------------------------------------------Profile setting ----------------------------------------------------
.slider-wrapper {
width: 760px;
margin: auto;
}
.slick-slide {
text-align: center;
position: relative;
}
.slick-slide:focus {
outline: none;
}
.slick-slide-title {
text-transform: capitalize;
}
.prof-slide{
  div{
    height: 7rem !important;
  }
}
.slick-prev:before, .slick-next:before {
color: #2c9db7 !important;
}
.ban-preview{
border: 1px solid #d0d0d0;
height: 350px;
width: 100%;
// border-radius: 5px;
padding: 10px;
.imgContiner1{
  width: 100%;
  height: 100%;
}
img{
  width: 100% !important;
  object-fit: fill !important;
}
}
////-----------------Product List---------------
.pre-vid{
width: 100%;
height: 20rem;
}
.MuiSlider-root {
color: #2c9db7 !important;
}
#Product{

.outline,.fill{
  position: absolute;
  right: 10px;
  top: 6px;
 cursor: pointer;
  font-size: 1.5rem;
  z-index: 2;
}
.fill{
  color: #e64c3c;
}
.outline{
  color: rgb(168, 168, 171);
}
.outline:hover{
  color: #e64c3c;
}
.fill:hover{
  color: rgb(168, 168, 171);
}
.productIcon{
 
i{
font-size: 20px;
position: absolute;
opacity: 0.7;
}

.Icon1,.Icon2,.Icon3,.Icon4{
  cursor: pointer;
  position: relative;
  width: 40px;
  padding: 9px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgb(238, 238, 238);

}
.Icon1:hover,.Icon2:hover,.Icon3:hover,.Icon4:hover{
  i{
    opacity: 1;
  }
  
}
.Icon2{
  padding: 8px;
}
.Icon3{
  padding: 10px;
}

}

.awards{
div{
width: 55px;
height: 55px;
border: 1px solid #eee;
margin-left: 1rem;
border-radius: 0.375rem;
img{
  width: 100%;
  height: 100%;
  background: #fff;
  object-fit: contain;
  border-radius: 0.375rem;
}
}
}

.products-card {

  background: #fff;
  width: 100%;
  height: 28rem;
  position: relative;
  border-radius: 0.375rem;
  overflow: visible;
  margin: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: inline-block; 

  .set-headline {
    width: 100%;
    height: 10%;
    // background: #eee;
    // margin: 20px; }
    h3{
      border-bottom: 1px solid #eee;
  white-space: nowrap;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 7px;
    }
  }
  .set-headline1 {
    width: 100%;
    height: 10%;
    // background: #eee;
    // margin: 20px; }
    h3{
      border-bottom: none;
  white-space: nowrap;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 35px;
    }
  }
  
  .brandImg{
    border-radius: 0.375rem;
    width: 65px;
    max-height: 65px;
    background: #fff;
    position: absolute;
    left: 10px;
    top: 10px;

    border: solid 1px #eeeeee;
    img{
       border-radius: 0.375rem;
      width:100%;
      height:100%;
      background: #fff;
      object-fit: contain;
    }
  }
  
.set-image {

  img{

    width:100%;
    height:100%;
    background: #fff;
    object-fit: contain;
    }
    border-radius: 0.375rem;
  
  width: 100%;
  height: 325px;
  max-height: 325px;
  min-height: 325px;
  background: #fff;
  overflow: hidden;
  font: bold 20px Sans-Serif;
  text-transform: uppercase;
  // display: table;
  .product-title{
    width: 46%;
    text-align: center;
    overflow: hidden;
    height: 80%;
    padding: 28px;
    display: inline-block;
    position: absolute;
  }

}

.set-image p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  line-height: 25px;
  color: #d2d2d2; }


}
.ribbon-wrapper-3 {
  width: 106px;
  height: 108px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  left: -6px; }

.ribbon-3 {
  font: bold 15px Sans-Serif;
  line-height: 18px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -33px;
  top: 26px;
  width: 150px;
  background-color: #2c9db7;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px; }

.ribbon-3:before, .ribbon-3:after {
  content: "";
  border-top: 4px solid #4e7c7d;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  bottom: -4px; }

.ribbon-3:before {
  left: 0; }

.ribbon-3:after {
  right: 0; }
}
// -------------------------------------Fav Popup---------------------------------------
.fav-list{
    padding-left: 1rem !important;
    padding-top: 0.80rem !important;
    border-radius: 5px !important;
}
.fav-count{
width: 35px;
height: 28px;
background: #fff;
color: #616365;
border-radius: 10%;
padding: 4px 0 0;
font-size: 14px;
float: right;
position: relative;
right: -6rem;
text-align: center;
right: -6rem;
top: -55px;
}
.fav-cl{
position: absolute;
background: #f8f8f8;
color: #2c9db7;
top: -10px;
right: -103px;
border: 1px solid #2c9db7;
border-radius: 50%;
padding: 1px 6px;
z-index: 3;
text-align: center;
}
.gestcomptitle {
  color: #2ca5cd !important;
}
.ribbon-9::before{
width: 0;
height: 0;
border-top: 15px solid transparent;
border-left: 25px solid #fff;
border-bottom: 17px solid transparent;
position: absolute;
top: 0;
left: 0;
content: "";
}
.ribbon-9{
top: -13rem;
width: 142px;
padding: 5px;
  position: relative;
  right: -15px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  float: right;
  margin-bottom: 10px;
  background: #5cbcb1;


}

///-----------------------------product-detail-----------------------------
.flag{
width: 20px;
height: 20px;
background-color: white;
}
#product-detail{
.slick-prev {
top: -1.5rem;
opacity: 1 !important;
left: 40%;
transform: rotate(90deg);
}
.slick-next{
top: 102%;
opacity: 1 !important;
left: 40%;
transform: rotate(90deg);
}
.slick-list{
height: 80vh !important;
}
.magnifier {
 height: 60vh !important;
}
.awd-gri{
flex: 0 0 10.66667% !important;
max-width: 10.66667% !important;
@media only screen and (max-width: 1600px) and (min-width: 900px){
flex: 0 0 12.66667% !important;
max-width: 20.66667% !important;
}
@media only screen and (max-width: 900px) and (min-width: 600px){
  flex: 0 0 auto !important;
  max-width: 33% !important;
}
@media only screen and (max-width: 600px){
  flex: 0 0 auto !important;
  max-width: 90% !important;
}
}
.key-cardawd {
padding:5px;
  background: #fff;
  // width: 100%;
  // height: auto;
    width: 160px;
  height: 200px;
  position: relative;
  border-radius: 0.375rem;
  overflow: visible;
  margin: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: inline-block; 
  border: 1px solid rgb(184, 184, 184);
  
  .set-headline {
    width: 100%;
    height: 22%;
    overflow: hidden;
    white-space: nowrap;
    // background: #eee;
    // margin: 20px; }
    h4{
      width: 98%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  // .set-headline:hover{
  //   white-space: normal !important;
  //   height: 22% !important; 
  // }

.set-image {
  img{

    width:100%;
    height:100%;
    background-color: #fff;
    object-fit: contain;
    }
    border-radius: 0.375rem;
    // border: 1px solid rgb(184, 184, 184);
  width: 100%;
  height: 100%;
  background: #eee;
  overflow: hidden;
  font: bold 20px Sans-Serif;
  text-transform: uppercase;
  //display: table;
  .product-title{
    width: 46%;
    text-align: center;
    overflow: hidden;
    height: 80%;
    padding: 28px;
    display: inline-block;
    position: absolute;
  }
}

.set-image p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  line-height: 25px;
  color: #d2d2d2; }


}

.key-card {
  padding:5px;
    background: #fff;
    // width: 100%;
    // height: auto;
      width: 190px;
    height: 240px;
    position: relative;
    border-radius: 0.375rem;
    overflow: visible;
    margin: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: inline-block; 
    border: 1px solid rgb(184, 184, 184);
    
    .set-headline {
      width: 100%;
      height: 22%;
      overflow: hidden;
      white-space: nowrap;
      // background: #eee;
      // margin: 20px; }
      h4{
        width: 98%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    // .set-headline:hover{
    //   white-space: normal !important;
    //   height: 22% !important; 
    // }
 
  .set-image {
    img{
  
      width:100%;
      height:100%;
      background-color: #fff;
      object-fit: contain;
      }
      border-radius: 0.375rem;
      // border: 1px solid rgb(184, 184, 184);
    width: 100%;
    height: 100%;
    background: #eee;
    overflow: hidden;
    font: bold 20px Sans-Serif;
    text-transform: uppercase;
    //display: table;
    .product-title{
      width: 46%;
      text-align: center;
      overflow: hidden;
      height: 80%;
      padding: 28px;
      display: inline-block;
      position: absolute;
    }
  }
  
  .set-image p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    line-height: 25px;
    color: #d2d2d2; }
  
  
}
  .ribbon-wrapper-3 {
    width: 106px;
    height: 108px;
    overflow: hidden;
    position: absolute;
    top: -6px;
    left: -6px; }
  
  .ribbon-3 {
    font: bold 12px Sans-Serif;
    line-height: 5px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    left: -33px;
    top: 26px;
    width: 150px;
    background-color: #2c9db7;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.5px; }
  
  .ribbon-3:before, .ribbon-3:after {
    content: "";
    border-top: 4px solid #4e7c7d;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px; }
  
  .ribbon-3:before {
    left: 0; }
  
  .ribbon-3:after {
    right: 0; }
  

p{
  font-size: 14px;
}
.prodcompanytitle{

    color: #a8a8ab !important;
  }
.price 
{
        font-size: 30px !important; 
}
.product-imgs{
  border-radius: 0.375rem;
  //width: 100%;
  width: 86px !important;
  min-height: 91px;
  max-height: 135px;
  background: #fff;
  padding: 5px;
  // border: solid 1px #eeeeee;
  @media only screen and (max-width: 1600px) and (min-width: 900px){
    width: 88px !important;
    min-height: 88px;
    max-height: 88px;
    img{
      max-height: 88px;
    }
}
cursor: pointer;
img{
  border-radius: 0.375rem;
 width:100%;
 height: 76px;
 background: #fff;
 object-fit: cover;
}
}
.js-image-zoom__zoomed-image{
z-index: 99 !important;
width: 67vh !important;
//height: 65vh !important;
top: 0px !important;
//left: 45px !important;
left: 156px !important;
height: 78vh !important;
background-color: white !important;
border: 1px solid #5e72e4 !important;
}
.prod-img{
display: flex !important;
align-items: center;
justify-content: center;
img{  
    width: 100% !important;
  height: 80vh !important;

}
}
.center-img{
justify-content: center;
display: flex;
align-items: center;
border-radius: 0.375rem;
//   width: 60vh;
//  height: 60vh;
width: 100%;
height: 100%;
background: #fff;
// width: 100%;
position: relative;
cursor: pointer;
// div:first-child{
//   width: 60vh !important;
//   height: 60vh !important;
// }
img{
border-radius: 0.375rem;
 width:100% !important;
height:100% !important;
 background: #fff;
 object-fit: contain;
}
.img-zoom-lens {
position: absolute;
border: 1px solid #d4d4d4;
/*set the size of the lens:*/
width: 100px;
height: 100px;
}
.img-zoom-container:hover{
.img-zoom-result{
//display: block;
visibility: visible;
} 
}
.img-zoom-result {
border: 1px solid #d4d4d4;
/*set the size of the result div:*/
position: absolute;
top: 0px;
left: 103%;
width: 85%;
  height: 100%;
  z-index: 99;
  visibility: hidden;
}
.img-title{
position: absolute;
right: 15px;
bottom: 10px;
}
}

.productIcon{
 
i{
  font-size: 20px;
  position: absolute;
  opacity: 0.7;
}

  .Icon1,.Icon2,.Icon3,.Icon4{
    cursor: pointer;
    position: relative;
    width: 40px;
    padding: 9px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgb(238, 238, 238);
 
  }
  .Icon1:hover,.Icon2:hover,.Icon3:hover,.Icon4:hover{
    i{
      opacity: 1;
    }
    
  }
  .Icon2{
    padding: 8px;
  }
  .Icon3{
    padding: 10px;
  }
  .heart-r{
    color: #e64c3c;
  }
  .heart-r:hover{
    
    color: #dcdcdc;
  }
}
.brandImg{
border-radius: 0.375rem;
width: 150px;
height: 150px;
background: #fff;
text-align: center;
border: solid 1px #eeeeee;
img{
   border-radius: 0.375rem;
  width:100%;
  height:100%;
  background: #fff;
  object-fit: contain;
}
}
.pointy {
position: relative;
background-color: orange;
margin: 0 20px;
display: inline-block;
font-family: arial;
font-weight: bold;
font-size: 14px;
line-height: 20px;
color: white;
}
.pointy:after {
position: absolute;
content: "";
height: 0;
width: 0;
font-size: 0;
line-height: 0;
border-top: 10px solid transparent;
border-bottom: 10px solid transparent;
border-left: 10px solid orange;
}
.pointy:before {
position: absolute;
content: "";
left: -20px;
height: 0;
width: 0;
font-size: 0;
line-height: 0;
border-top: 10px solid orange;
border-bottom: 10px solid orange;
border-right: 10px solid orange;
border-left: 10px solid transparent;
}
.items{
background-color: rgb(139, 150, 162);
color: white;
width: 120px;
white-space: nowrap;
font-weight: 500;
overflow: hidden;
text-overflow: ellipsis;
}
.qt{
width: 60px;
height: 35px;
color: black;
}
.key{
border-radius: 0.375rem;
width: 70px;
height: 70px;
background: #fff;
text-align: center;
border: solid 1px #eeeeee;

img{
   border-radius: 0.375rem;
  width:100%;
  height:100%;
  background: #fff;
  object-fit: contain;
}
}
.more{
position: absolute;
right: 10px;
bottom: -40px;
cursor: pointer;
}
.active-btn{
color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}
.prod-large-img{
z-index: 9999;
}


}

.eventtop{  
    justify-content: center;
  align-items: center;
  min-height: 100vh;
  background :#dcf1f1;
  overflow: hidden;
  padding: 100px 20px;
  box-sizing: border-box;
}




.evndt{
border: 2px dashed rgb(107, 91, 91);
padding: 1rem;
}
.evnnum{
border: 4px solid rgb(161, 130, 49);
width: 22%;
margin-left: 23rem;
}
.numcard{
background-color: rgba(255, 255, 255, 3.4);
z-index: 4;
}

.outer_badge{
background-color: white;
color: rgb(255, 99, 71);
border: 1px solid rgb(255, 99, 71);
}
.active-img{
border:1px solid #2c9db7;
}
.btn-secondary:not(:disabled):not(.disabled).active{
background-color: #2c9db7 !important;
color: #fff;
}
.Cardborder{
// border: 1px solid #e6ecf1;
border-radius: .25rem;
}


@media only screen and (min-width: 900px) {
.evnnum{
     margin-left: 37rem;
}
}


@media screen and (max-width: 1124px) and (min-width: 602px) {
.evnnum{
  margin-left: 23rem;
}
}
@media screen and (max-width:1950px ) and (min-width: 1440px) {
.evnnum{
  margin-left: 25rem;
}
}
//------------------------------------Notification----------------------------------
#Notification{


.badge-blue{
background-color: #2c9db7;
color: white;
border: 1px solid white;
}
.noty{
i{
  position: absolute;
}
position: relative;
  width: 40px;
  height: 40px;
  padding: 9px;
  font-size: 1.2rem;
  border: 2px solid #eee;
  border-radius: 50%;
}
.notific{
height: 65vh;

}
.unreed {
color: #525f7f;
background-color: #f6f9fc; }
.unreed:hover {
  color: #525f7f;
  background-color: #fff; 
}
}
//-----------------------------------------Favourites------------------------------
#Favourites{
.active{
  background-color: #fff !important;
  border-bottom: none !important;
}
.count{
  position: relative;
  left: 25%;
}
}
#MyProduct{
.active{
  background-color: #fff !important;
  border-bottom: none !important;
}
.count{
  position: relative;
  left: 25%;
}
}

///-------------Search Modal----------------
.searchHeight{
height: 550px;
transition: height 0.5s ease-in;
}
// ---------------------------------------Profile Settings --------------------------
.comp-name{
width: 100%;
@media only screen and (max-width: 1600px) and (min-width: 900px){
// .MuiInputLabel-outlined{
//   transform: translate(5px, 20px) scale(1) !important;
// }
}
}
.addrs{
.MuiOutlinedInput-root{
  height: 217px;
}
}
.reg12{
width: 24.8rem !important;
}
.reg21{
width: 24.8rem !important;
}
.ftl-img{
width: 75px;
height: 75px;
border: 1px solid #c6c6c6;
padding: 5px;
img{
  width: 100%;
  height: 100%;
}
}
.active-bt{
  color: #fff;
  background-color: #2c9db7 !important;
  border-color: #2c9db7 !important;
}
.stripe{
background-image: -webkit-gradient(linear,left top,left bottom,from(#7dc5ee),color-stop(85%,#008cdd),to(#30a2e4));
}
.set-prof{
  width: 98px;
  height: 98px;
  border: 1px solid #c4c4c4;
  padding: 5px;
  @media only screen and (max-width: 1600px) and (min-width: 900px){
    width: 80px !important;
  height: 80px !important;
  }
  img{
    width: 100%;
    height: 100%;
  }
}
.settings{
.nav-item{
    cursor: pointer !important;
}
}
.ftl-img1{
width: 65px;
height: 65px;
border-radius: 20px;
padding: 5px;
img{
  width: 100%;
  height: 100%;
}
}
.user-card{
// height: 6rem;
@media only screen and (max-width: 900px){
  height: auto !important;
  .res-i{
    width: 100% !important;
    text-align: center !important;
    }
  }
  .custom-toggle input:checked + .custom-toggle-slider:before {
    background: #2dce89;
    transform: translateX(28px) !important;
  }
  .custom-toggle-success input:checked + .custom-toggle-slider:after {
    color: #2dce89 !important;
    margin-left: 5px !important;
  }
}
@media (min-width:1201px) and (max-width:1350px){
  .dash-card{
    width: 23%;
  }
}
@media (min-width:992px) and (max-width:1200px){
  .dash-card{
    width: 23%;
  }
  
}

.viewcard{
  max-height: 20rem;
  overflow: auto;
}
.fa-pencil-alt{
cursor: pointer;
}
.tip{
  position: relative;
  top: -11px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #46b88f;
  @media only screen and (max-width: 1600px) and (min-width: 900px){
  top: -10px !important;
  height: 9px !important;
  width: 9px !important;
  }
}
.pt4{
padding-top: 3.5rem;
}

///-----------------------------events------------------------------------

#Events{
.event-card{
  background-color: #f8f9fe;

}
.event-logo
{
  width: 130px;
  height: 130px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  @media only screen and (max-width: 1600px) and (min-width: 900px){
    width: 95px !important;
    height: 95px !important;
  }
}
.event-title
{
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 25px;
  line-height: 27px;
  text-decoration: none;
}
.event-Venue
{
font-weight: 600;
 
}
.event-venue-detail
{
font-size:14px;  
font-weight: 600;
}

}
// ...................................MouseOverpopup-----------------------------------
.MuiPopover-paper{
background-color: #e9ecef !important;
}

///-----------------------------iner------------------------------------

#iner{
a {
  color: #808080;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #525f7f;
    text-decoration: none; }
.progress-bar
{
  background-color: #2c9db7;
}
}
// --------------------------------------Myproductlist------------------------------------
.right-search1{
position: relative;
width: 20rem;
top: -10px;

}
.right-search2{
  position: relative;
top: 3.2rem;
right: -46rem;
width: 20rem;
@media only screen and (max-width: 1600px) and (min-width: 900px){
  right: -30rem !important;
}
}
.right-sort1{
position: relative;
  // top: 3.5rem;
  // right: -58rem;
  // @media only screen and (max-width: 1600px) and (min-width: 900px){
  //   right: -41rem !important;
  // }
}
.right-sort2{
  position: relative;
    top: 3.5rem;
    right: -47rem;
    @media only screen and (max-width: 1600px) and (min-width: 900px){
      right: -31rem !important;
    }
  }
.res-md{
  h3{
    width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }  
@media only screen and (max-width: 1600px) and (min-width: 900px){
padding-left: 2.5rem !important;
}
}
.res-spa{
width: 75%;
  height: 2rem;
  padding: 0.6rem;
  border: 1px solid #46b88f;
  color: #46b88f;
  @media only screen and (max-width: 1600px) and (min-width: 900px){
  width: 100% !important;
  padding: 0.6rem 0rem 0.6rem 0.5rem !important;
}
}
.ftl-img12{
width: 108px;
height: 108px;
border-radius: 20px;
padding: 5px;
img{
  width: 100%;
  height: 100%;
}
}
/////-----------------------------------PaymentConfirm----------------------------
#PaymentConfirm{
.amt{
  color: #525f7f !important;
}
.rowBox {

  padding: 1.25rem;
  border: 1px solid #e6ecf1;
  border-radius: .25rem;
  margin-bottom: 2rem;
  img{
    width: 100px;
    height: 50px;
    object-fit: contain;
  }
}
.leftCol-top{
border-top-left-radius: 25px;
border-top-right-radius: 25px;
}

.leftCol-bottom{
border-bottom-left-radius: 25px;
border-bottom-right-radius: 25px;
}
.left{
background: #f8f9f4;
}
.scrolhide{
white-space: normal !important;
}
.btn-apply{
padding: 0.500rem 1.25rem ;
margin-left: -2px;
}
.paycarhr{
border-bottom: 2px solid #2c9db7;
}
.ordersumsplit{
border-bottom: 1px #d9d9d9;
}
.couponvalid{
color: #1eab15
}
.couponvalid-top{
color: #1eab15;
font-size: 12px;
 margin-left: 5px;
}
.couponinvalid{
color: #f5365c
}
.changeplan{
color:#2c9db7;
cursor: pointer;
}
}

///------------------------profile view-------------------------------------------- 
#profileview{
.ribbon-wrapper-5 {
z-index: 2;
    width: 106px;
    height: 108px;
    overflow: hidden;
    position: absolute;
    top: -6px;
    left: -6px; }
  
  .ribbon-5 {
    z-index: 2;
    font: bold 13px Sans-Serif;
    line-height: 10px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    left: -33px;
    top: 26px;
    width: 150px;
    background-color: #2c9db7;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    letter-spacing: 1.5px; }
  
  .ribbon-5:before, .ribbon-5:after {
    content: "";
    border-top: 4px solid #4e7c7d;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px; }
  
  .ribbon-5:before {
    left: 0; }
  
  .ribbon-5:after {
    right: 0; }
  }
  // --------------------------------------------Cart---------------------------------------------
.badges-res{
width:75%;
height:2rem;
padding:0.6rem;
border:1px solid #2c9db7;
color:#2c9db7
}
.badges-res1{
width:75%;
height:2rem;
padding:0.6rem;
border:1px solid #46b88f;
color:#2c9db7
}
.pri-data{
position: absolute;
  top: 3.4rem;
  left: 2rem;
  @media only screen and (max-width: 900px){ 
  position: relative !important;
  top: -1.6rem !important;
  left: 0px !important;
  }
}
.pinp{
padding: 2.7rem 12rem 1.5rem 1rem;
@media only screen and (max-width: 900px){
  padding: 0px 65px !important;
}
@media screen and (max-width:1900px ) and (min-width: 1640px) {
  padding: 2.7rem 8rem 1.5rem 1rem !important;
}
@media only screen and (max-width: 1600px) and (min-width: 900px){
  padding: 2.7rem 5rem 1.5rem 1rem !important;
}
}
.pri-rate{
position: relative;
top: -24px;
left: 3rem;
}
.p-top{
margin-top: -35px !important;
@media only screen and (max-width: 900px){
  margin-top: 0px !important;
}
}
.cart-r{
padding:1rem;
height:11rem;
@media only screen and (max-width: 900px){
  height:auto !important;
}
}
.sm-vis{
display: block;
@media only screen and (max-width: 900px){
  display:none !important;
}
}
.sm-vis1{
display: none;
@media only screen and (max-width: 900px){
  display:block !important;
}
}
.res-mob-01{
@media only screen and (max-width: 900px){
  padding: 1rem 0px 0px !important;
}
}
.ml60{
@media only screen and (max-width: 900px){
  margin-left: -60px !important;
}
}
.res-c{
@media only screen and (max-width: 900px){
  text-align: center !important;
}
}
.ml4i{
margin-left: 1.5rem !important;
@media only screen and (max-width: 900px){
  margin-left: 0px !important;
}
}
.ml25{
@media only screen and (max-width: 900px){
  margin-left: -40px !important;
  .pri-rate{
    left: 1rem !important;
  }
}
}
.l{
@media only screen and (max-width: 900px){
   left: 0.5rem !important;
  }
}
// .fav-cl2{
//     position: relative;
//     background: #f8f8f8;
//     width: 25px;
//     color: #2c9db7;
//     top: 15px;
//     right: -147px;
//     border: 1px solid #2c9db7;
//     border-radius: 50%;
//     padding: 1px 6px;
//     z-index: 3;
//     text-align: center;
// }
// .B-prod1{
//   width: 10rem;
//   height: 3rem;
//   padding-top: 17px;
//   font-weight: 700;
//   font-size: 0.9000rem !important;
//   background-color: #2c9db7;
//   color: #fff;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
// }
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
color: #666;
font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
text-align: center;
cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
max-height: 600px;
}
.advanced{
position: relative;
top: 13px;
left: 250px;
transition: background-color 0.3s linear;
transition-delay: 0.15s;
-webkit-transition: all 0.5s ease 0s;
-moz-transition: all 0.5s ease 0s;
-o-transition: all 0.5s ease 0s;
transition: all 0.5s ease 0s;
border-radius: .25rem !important;
background-color: rgba(255, 255, 255, 0.9)  !important;
border-color: rgba(255, 255, 255, 0.9) !important;
color: rgba(0, 0, 0, 0.9) !important;
}
.TopSearch{
input{
  padding: 1rem;
  border-radius: 2rem;
  height: 46px;
  width: 500px !important;
  border: 0 solid;
  color: rgba(0, 0, 0, 0.9);
  background-color: rgba(255, 255, 255, 0.9);
  border-color: rgba(0, 0, 0, 0.6);
  transition: background-color 0.3s linear;
  transition-delay: 0.15s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
input:focus{
  width: 850px !important;
  box-shadow: none !important;
  .advanced{    left: 600px;}
}

input::placeholder{
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.8;
}
.ant-input-group-addon{
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
  background-color: #eaf5f8;
  left: 0px;
  .anticon{
    line-height: 2;
  }
}
.ant-input-group-addon:hover{
  button{
background-color: transparent !important;
  }
} 
.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
  border-color: #eaf5f8 !important;
}
group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
border-left-color: #eaf5f8 !important;
}
.ant-input-search-button:hover, .ant-input-search-button:focus {
z-index: 1;
background-color: #eaf5f8 !important;
}
button{
background-color: transparent;
border: none;
border-bottom-right-radius: 2rem;
border-top-right-radius: 2rem;
}
}
.ant-select-auto-complete {
-webkit-box-sizing: border-box;
        box-sizing: border-box;
margin: 0;
padding: 0;
color: rgba(0, 0, 0, 0.85);
font-size: 14px;
font-variant: tabular-nums;
line-height: 1.5715;
list-style: none;
-webkit-font-feature-settings: 'tnum';
        font-feature-settings: 'tnum';
}
.ant-select-auto-complete .ant-select-clear {
right: 13px;
}
.active-Plan{
color: #2c9db7 !important;
}
.active-dis{
color: #aaaaaa !important;
}
.active-Plan-hr{
width: 67% !important;
}
// --------------------------------------------Searchtab ------------------------------------------------
.list-tab{
.list-main1{
    margin-right: 10px;
    @media only screen and (max-width: 600px){
        width: 100% !important;
        }
      }
      .nav-link{
        width: 100%;
        height: auto;
        padding: 20px;
        background-color: #e9ecef;
        @media only screen and (max-width: 600px){
            width: 100% !important;
            }
        }
    }
// -----------------------------------------import/export---------------------------------------------------
.imprt{
height: 22rem;
@media only screen and (max-width: 600px){
  height: auto !important;
  }
.mt2-5{
  margin-top: 2.5rem;
}
}
.card-lift--hover:hover {
transform: translateY(-20px);
transition: all .15s ease;
}
.top-import{
width: 100%;
height: 30rem;
background-color: #11cdef;
h4,h1{
  color: #fff;
}
.clip-div{
  height: 10rem;
  clip-path: polygon(100% 0, 0 0, 0 100%);
  background-color: #2c9db7;
}
}
.imp-card{
position: relative;
top: -6rem;
}
.imp-doc{
border: 1px solid #b3b3b3;
height: 7rem;
padding: 0.5rem;
border-radius: 10px;
}
.img-impo{
width: 100%;
height: 100%;
}
.success-i{
font-size: 3rem;
color: #2dce89;
}
.fail-i{
font-size: 3rem;
color: #ce3a2d;
}
.buz-01{
.MuiAutocomplete-popperDisablePortal {
  position: absolute;
  width: 255px !important;
}
}

//----------------------Vendor Dashboard------------------------------
#bodashboard{
.fire{
  color: #F73718  ;
}
.borders{
  border-left: 6px solid #2c9db7;
}
.CountBox{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: flex-start;
}
.linkedin{
  background-color: #0a66c2 !important;
  color: white;
}
.comp_count{
  font-size: 2.5rem;
}
.chart{
  height: 400px;
}
thead th{
  font-size: 0.8125rem;
  text-transform: capitalize;
  background-color: white !important;
}
.rightCard{
position: relative;
top: -30px;
border-top: 1px solid #e9ecef;
}  
}
.overall{
 height: auto;
 border: 1px #ccc solid;
 border-radius: 3px;
 padding: 1rem;
} 
.overall:active{
 height: auto;
}
.fav-cl1 {
position: relative;
background: #f8f8f8;
color: #2c9db7;
top: 15px;
left: 93%;
border: 1px solid #2c9db7;
border-radius: 50%;
padding: 1px 6px;
z-index: 3;
/* text-align: center; */
width: 26px;
height: 27px;
/* float: right; */
}
.txt-elps{
width: 100%;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
}
// ------------------------------------------Documents-------------------------------------
.ofc-doc{
  padding: 5px;
  background: #fff;
  width: 155px;
  height: 195px;
  position: relative;
  border-radius: .375rem;
  overflow: visible;
  margin: 5px;
  box-shadow: 0 0 15px #0000001a;
  display: inline-block;
  border: 1px solid #b8b8b8;
}
.doc-image{
  border-radius: .375rem;
  width: 100%;
  height: 100%;
  background: #eee;
  overflow: hidden;
  font: 700 20px Sans-Serif;
  text-transform: uppercase;
}
.doc-name{
text-align: -webkit-center;
h4{
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
}
.avatar img{
  height: 100%;
  object-fit: cover;
}
// ----------------------- guest prod list ----------------------------
.brnd-log{
border: 1px solid #adb5bd;
width: 55px;
top: 10px;
left: 10px;
padding: 0.5rem;
border-radius: 15px;
height: 55px;
position: absolute; 
}
.brnd-log:hover{
background-color: #ffffff78;
border: 1px solid #ffff;
opacity: 1;
}
.badge-1{
width: 100%;
height: 1.5rem;
padding: 8px;
background-color: #2c9db7 !important;
border-color: #2c9db7 !important;
color: #ffff;
}
.awd-list{
border: 1px solid #c4c7ca;
  padding: 10px;
  border-radius: 8px;
  background-color: #d7d7d7;
}
.awd-bdy{
height: 17rem;
overflow-y: scroll;
}
.mt1-5{
margin-top: -0.15rem;
}
.comp-pics{
img{
  width: 100%;
  object-fit: contain;
}
}
.comp-detpic{
  // border: 1px solid #b8b8b8;
  width: 30%;
  height: 26%;
  top: 16rem;
  left: 2.5rem;
  border-radius: 5px;
  position: absolute;
  @media only screen and (max-width: 1600px) and (min-width: 900px){
    top: 17rem !important;
  }
  .comp-detpich{
    margin-top: 1rem;
    @media only screen and (max-width: 1600px) and (min-width: 900px){
      margin-top: 1.5rem !important;
    }
  }
  img{
    width: 100%;
    object-fit: contain;
  }
  .list-pic{
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    overflow: hidden;
  }
}
.fact-icon{
width: 70px;
height: 70px;
padding: 0.4rem;
img{
  width: 100%;
  object-fit: contain;
}
}
.fa-s{
// font-size: 43px;
font-size: 32px;
@media only screen and (max-width: 1600px) and (min-width: 900px){
  // font-size: 34px !important;
  font-size: 25px !important;
}
}
.right-arrow{
position: absolute;
  right: 1rem;
  top: 42%;
  font-size: 35px;
  color: #adb5bd;
@media only screen and (max-width: 600px){
  right: 1rem !important;
  }
}
.left-arrow{
position: absolute;
left: 1rem;
top: 42%;
font-size: 35px;
color: #adb5bd;
@media only screen and (max-width: 600px){
  left: 1rem !important;
  }
}
.right-arrow:hover, .left-arrow:hover{
font-size: 48px;
top:40%;
color: #ffff;
}
.algn{
position: absolute;
left: 1.4rem;
top: 5.79rem;
background-color: #ffff;
}
.ch-edit{
position: absolute;
right: 1.5rem;
}
.ch-edit1{
position: absolute;
right: 3rem;
margin-top: 2.5rem;
}
.prod-slide{
width: 100%;
height: 100%;
// border: 1px solid red;
padding: 1rem;
.slide_img{
  width: 100%;
  height: 100%;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
}
// ------------------------------------------topSearchDrawer-----------------------------------------------
.searchlist{
  .navbar-search .form-control{
    width: 30rem !important;
  }
}
.faplus11{
  margin-top: 2rem !important;
    color: #5cbcb1;
    font-size: large;
    cursor: pointer !important;
    margin-left: 1rem;
}
// -------------------------------meeting-----------------------------
.meet{
  border: 1px solid gray;
  border-radius: 10px;
}

.searchcontainer{
  height: 85vh;
  // height: 20vh;
  .searchbox{
    top: 45%;
    left: 60%;
      position: absolute;
  }
}
.namedesc{
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4c4c4c;
  width: 6rem;
  cursor: pointer;
  }

.rightBorder{
  border-right: 2px solid #e9ecef;
}

.pointer{
  cursor: pointer;
  }
  .res-top{
    @media only screen and (device-width: 1366px){
      padding-top: 10rem !important;
      }
      @media only screen and (device-width: 1360px){
        padding-top: 10rem !important;
        }
  }

  .Checkmark{
    position: absolute;
      right: -2.2rem;
      top: 1rem;
      background: #F7FAFE;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.23);
  }
  .Checkspan{
    margin-top: 0.4rem;
  margin-left: 0.5rem;
  }
  .ellipsisText{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    display: block !important;    padding-top: 0.8rem;
  }
  
  .newdesc{
    font-size: 15px !important;
      line-height: 24px !important;
      color: #000000 !important;
  }
  #certificateName{
    height: 56px;
  }
  #Vat{
    height: 56px;
  }

  .didy{
    font-size: 1.8rem;
    color: #2c9db7;
    position: relative;top: 7px;
  }
  
  .handshak{
    .tooltip-inner {
      background-color: lightgrey;
      color: #3f5155;
    }
    
     .tooltip.bs-tooltip-bottom .arrow:before {
     border-bottom-color: lightgrey;
     color: #2dce89;
    }
    
  }
  .react-daterange-picker__wrapper{
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: thin solid white !important;
  }
  .react-daterange-picker__button {
  padding: 4px 2px !important;
}
.react-daterange-picker__inputGroup {
  padding: 2px 2px !important;
}
.MuiAccordionDetails-root{
  display: block !important;
  padding: 8px 16px 16px;

}
.Evesort{
  position: absolute;
  top: -0.4rem;
  right: 5rem;
}
.Evesearch{
  position: absolute;
    top: -0.9rem;
    right: 9rem;
    width: 20rem;
}

.channelsearch{
  position: absolute;
    top: -0.9rem;
    right: 9rem;
    width: 20rem;
    .MuiInputLabel-outlined{
      top: 8px!important;
    }
    .MuiAutocomplete-input{
      padding: 12.5px 4px!important;
    }
}
#Coupon{
  .MuiFormControl-root{
    width: 100%;
  }
}
.chan-type .MuiInputBase-root{
height: 54px !important;
}
.Evedes{
  white-space: normal !important;
}
.custom-accordion .MuiCollapse-root{
  position: absolute;width: 100%;background-color: #fff;z-index: 2;top: 60px;
}
.listbill th{
  font-size: 14px !important;
}
@media (min-width:1200px) and (max-width:1400px){
  .image-hint{
    padding-left: 20px;
  }
} 
@media(min-width:992px) and (max-width:1199px){
  .Evedes{
    width: 400px;
  }
  .comp-pic p{
    float: left;
  }
  .image-hint{
position: relative;left: -30px;
  }
}
@media(min-width:992px) and (max-width:1065px){
  
  .image-hint{
position: relative;left: -10px;
  }
}
@media(min-width:768px) and (max-width:991px){
  .Evedes{
    width: 350px;
  }
  .Reorderdialog.editreorderdialog{
    max-width: 70% !important;
  }
}
@media(max-width:767px){
  .Evedes{
    width: 300px;
  }
  .event-nav li{
    width: 48% !important;margin-top: 10px;
  }
  .table-responsive #datatable-basic_filter .btn{
    margin-top: 10px;
  }
  .mob-para{
    margin-top: 10px !important;
  } 
  .Reorderdialog.editreorderdialog{
    max-width: 75% !important;
  }
}
@media (max-width:550px){
  .ban-preview{
    margin-top: 40px;
  }
  .Reorderdialog.editreorderdialog{
    max-width: 90% !important;
  }
}
@media (min-width:768px) and (max-width:991px){
  .dash-card{
    width: 31%;
  }
  .searchlist .navbar-search .form-control{
    width: 20rem !important;
  }
  .searchcontainer .searchbox{
    left: 27% !important;
  }
  .searchcontainer{
    height: 30vh;
  }
}
@media (min-width:570px) and (max-width:767px){
  .dash-card{
    width: 44%;
  }
  .searchcontainer{
    height: 20vh;
  }
  .searchlist .navbar-search .form-control{
    width: 20rem !important;
  }
  .searchcontainer .searchbox{
    left: 18% !important;
  }
}
@media (max-width:569px){
  .dash-card{
    width: 85%;
  }
}
.code .comp-name1.switch-number{
  width: 100%;
}
.overdot div{
  font-weight: initial !important;
}